<template>
  <div>
    <v-page-title title="Resources" />

    <v-card max-width="800" class="mx-auto" elevation="0" color="transparent" v-show="false">
      <v-card color="blue-grey darken-4" dark class="rounded-xl text-center py-8" elevation="0">
        <v-card-text>
          Version 1.0 of the Hounddog Connect Wordpress Plugin is in Beta.<br>
          <v-btn>Download</v-btn>
        </v-card-text>
      </v-card>
    </v-card>

    <v-card max-width="1200" class="mx-auto my-5" elevation="0" color="transparent">
      <v-container>
        <v-row>
          <v-col md="6" v-for="(group, index) in groups" :key="`group-`+index">
            <v-card elevation="0">
              <v-card-title>{{group.name}}</v-card-title>
              <v-card-text>
                <v-list dense>
                  <v-list-item-group>
                    <v-list-item :href="item.url" target=_blank v-for="(item, index) in group.list" :key="`list-`+index">
                      <v-list-item-content>
                        <v-list-item-title>{{item.name}}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  
  </div>
</template>

<script>
  export default {
    name: 'Resources.vue',
    data() {
      return {
        groups:[
          {
            name: 'Wordpress Plugins',
            list: [
              {
                name: 'Wordfence',
                url: 'https://wordpress.org/plugins/wordfence/'
              },
              {
                name: 'Google SiteKit',
                url: 'https://wordpress.org/plugins/google-site-kit/'
              },
              {
                name: 'Activity Log',
                url: 'https://wordpress.org/plugins/aryo-activity-log/'
              },
              {
                name: 'Advanced Custom Fields',
                url: 'https://wordpress.org/plugins/advanced-custom-fields/'
              },
              {
                name: 'Disable Comments',
                url: 'https://wordpress.org/plugins/disable-comments/'
              },
              {
                name: 'Query Monitor',
                url: 'https://wordpress.org/plugins/query-monitor/'
              },
              {
                name: 'Redirection',
                url: 'https://wordpress.org/plugins/redirection/'
              },
              {
                name: 'Short Code Finder',
                url: 'https://wordpress.org/plugins/shortcodes-finder/'
              },
              {
                name: 'WP DB Manager',
                url: 'https://wordpress.org/plugins/wp-dbmanager/'
              }
            ]
          },
          {
            name: 'General Sites',
            list: [
              {
                name: '\';--have i been pwned?',
                url: 'https://haveibeenpwned.com/'
              }
            ]
          },
          {
            name: 'Chrome Extensions',
            list: [
              {
                name: 'WP Hive',
                url: 'https://chrome.google.com/webstore/detail/wp-hive-a-better-wordpres/hbbcblcogociooaeodndcimehhchadka?hl=en',
              }
            ]
          }
        ]
      }
    },
    computed: {

    }
  }
</script>

<style lang="scss">
</style>